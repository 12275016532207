import React from "react";

const Footer = () => (
  <footer className="footer f fdr x">
    <a
      href="mailto:andy@wearemage.com?subject=Hi, Mage..."
      target="_blank"
      rel="noopener noreferrer"
    >
      e-mail
    </a>
    <a
      href="https://instagram.com/wearemage"
      target="_blank"
      rel="noopener noreferrer"
    >
      instagram
    </a>
    <a
      href="https://www.youtube.com/watch?v=gO3cFz53vs8"
      target="_blank"
      rel="noopener noreferrer"
    >
      aim
    </a>
  </footer>
);

export default Footer;
