import React from "react";
import mage from "../assets/images/mage.svg";
import scrollTo from "gatsby-plugin-smoothscroll";

const Masthead = () => (
  <section className="masthead u-h--full">
    <section className="masthead-copy">
      <div className="topLeft">
        <h2>hi, we're mage</h2>
      </div>
      <div className="topRight">
        <h2>a digital studio in sf, ca</h2>
      </div>
      <div className="bottomLeft">
        <h2>please scroll around</h2>
      </div>
      <div className="bottomRight">
        <h2>
          <a className="mageMail" onClick={() => scrollTo("#reachOut")}>and reach out</a>
        </h2>
      </div>
    </section>
    <figure className="masthead-logo">
      <img src={mage} />
    </figure>
    <div className="particleContainer">
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle" />
      <div className="particle go-away" />
      <div className="particle go-away" />
      <div className="particle go-away" />
      <div className="particle go-away" />
      <div className="particle go-away" />
      <div className="particle go-away" />
      <div className="particle go-away" />
      <div className="particle go-away" />
      <div className="particle go-away" />
      <div className="particle go-away" />
      <div className="particle go-away" />
      <div className="particle go-away" />
      <div className="particle go-away" />
      <div className="particle go-away" />
      <div className="particle go-away" />
    </div>
  </section>
);

export default Masthead;
