import React from "react";

const Statement = () => (
  <section className="statement">
    <h1>
      mage builds brands,
      <br />
      online experiences,
      <br />
      and relationships.
    </h1>
  </section>
);

export default Statement;
