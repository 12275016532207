import React from "react";
import PropTypes from "prop-types";

import Header from "../components/header";
import Masthead from "../components/masthead";
import Statement from "../components/statement";
import ReachOut from "../components/reachOut";
import Footer from "../components/footer";
import Menu from "../components/menu";

import "../assets/scss/main.scss";

const Layout = ({ children }) => (
  <>
    <Header siteTitle="hi, we're mage" />
    <Masthead />
    <Statement />
    <div className="f">
      <div className="x y">{children}</div>
    </div>
    <ReachOut />
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
